// src/api.js
const BASE_URL = 'https://buh4scibb2.execute-api.us-east-1.amazonaws.com/prod'

/**
 * Function to fetch book samples from the backend API.
 * @param {string} bookType The type of book (e.g., 'fiction', 'non-fiction').
 * @param {string} style The style or author name described by the user.
 * @param {string} description A description of what the book talks about.
 * @returns {Promise<Array>} A promise that resolves to an array of sample books.
 */
export const fetchSamples = async (bookType, style, description, token) => {
    console.log(`Fetching samples for: ${bookType}, ${style}, ${description}`);
    let url = `${BASE_URL}/get-samples`;

    // If the token is null, append /public to the URL and remove the Authorization header
    if (!token || token === 'null') {
        url += '/public';
        token = 'null';  
    }

    let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
    };

    

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                genre: bookType,
                author_style: style,
                book_description: description
            })
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();

        // Assuming the response structure matches your sample and extracting plot details
        // Split the content string into separate samples based on a specific pattern
        const allPlotsContent = data.choices[0].message.content; // This assumes there's only one choice and all plots are in its content
        const plotDescriptions = allPlotsContent.split(/Plot \d+:/).slice(1); // Use regex to split by "Plot X:" and remove the first empty element caused by split
        
        const samples = plotDescriptions.map((plot, index) => {
            // Split the plot into title and the rest of the content
            const [title, ...rest] = plot.trim().split(/\n/); // Assumes the title is followed by two newlines
            const content = rest.join("\n\n").trim(); // Join the rest back together and trim any excess whitespace
        
            return {
                id: index + 1,
                title: title, // The title extracted from the beginning of each plot description
                content: content // The content of the plot, excluding the title
            };
        });

        console.log(samples);
        return samples;
    } catch (error) {
        console.error('Error fetching book samples:', error);
        // Handle the error as appropriate for your application
        return []; // Returning an empty array as a fallback
    }
};

// src/api.js
export const fetchBookCover = async (plot, style, chapter_number, bookId, token) => {
    console.log(`Requesting book cover for: ${plot}, ${style}`);
    let url = `${BASE_URL}/chapter-and-book`;
    // If the token is null, append /public to the URL and remove the Authorization header
    if (!token || token === 'null') {
        url += '/public';
        token = 'null';  
    }

    let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
    };


    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                plot,
                style,
                chapter_number,
                bookId,
            })
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log('Book cover generated:', data);
        return data.imageData;
    } catch (error) {
        console.error('Error generating book cover:', error);
        throw new Error('Failed to generate book cover');
    }
};

/**
 * Function to fetch a chapter and book details for storytelling purposes.
 * @param {string} plot The plot of the story.
 * @param {string} style The writing style or author style.
 * @param {number} chapter_number Which chapter to fetch.
 * @param {string} bookId The ID of the book.
 * @returns {Promise<Object>} A promise that resolves to an object containing the chapter content and options.
 */
export const fetchChapterAndBookDetails = async (plot, style, chapter_number, bookId, token, story_so_far) => {
    console.log(`Fetching chapter details for: ${plot}, ${style}, chapter_number: ${chapter_number}, bookId: ${bookId}`);
   
    let url = `${BASE_URL}/create-chapter-get-options`;
    // If the token is null, append /public to the URL and remove the Authorization header
    if (!token || token === 'null') {
        url += '/public';
        token = 'null';  
    }
    let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
    };

    

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                plot,
                style,
                chapter_number,
                bookId,
                story_so_far,
            })
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log('Chapter details fetched:', data);
        return {
            book_id: data.book_id,
            chapter_content: data.chapter_content,
            options: data.options
        };
    } catch (error) {
        console.error('Error fetching chapter details:', error);
        throw new Error('Failed to fetch chapter details');
    }
};

/**
 * Function to retrieve previously generated books from the API.
 * @param {string} token The user's authentication token.
 * @returns {Promise<Object>} A promise that resolves to an array of book objects.
 */
export const fetchGeneratedBooks = async (token, book_id) => {
    console.log(`Fetching previously generated books`);
    let url = '';
    if(book_id)
        url = `${BASE_URL}/profile-get-archive?book_id=`+ book_id;
    else
        url = `${BASE_URL}/profile-get-archive?book_id=`;
    let headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
    };

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: headers
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status}`);
        }

        const data = await response.json();
        console.log('Previously generated books fetched:', data);
        return data;
    } catch (error) {
        console.error('Error fetching previously generated books:', error);
        throw new Error('Failed to fetch previously generated books');
    }
};
