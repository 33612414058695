import React from 'react';

const MultiAgentSystem = () => {
  return (
    <div className="multi-agent-system-container">
        <div className='row'>
            <div className='col-md-6'>
            <div className="image-container">
                <img src='images/robots.png' alt="Robots Running Around" />
            </div>
        </div>
        <div className='col-md-6'>
            <div className="description-container card-text-left">
                <h2>How Our Multi-Agent AI System Works</h2>
                <p>When you enter a book description, our system springs into action:</p>
                <ul>
                <div className=''>
                        <p><strong>Binder Bot:</strong> Gathers your ideas.</p>
                        <p><strong>Writer Bot:</strong> Starts drafting the samples.</p>
                        <p><strong>Editor Bot:</strong> Refines the drafts for clarity and impact.</p>
                        <p><strong>Reviewer Bot:</strong> Ensures quality and consistency.</p>
                        <p><strong>Artist Bot:</strong> Adds the creative art.</p>
                </div>
                </ul>
                <p>In the end, all their efforts are combined into your final book, ready to amaze readers.</p>
            </div>
        </div>
        </div>
    </div>
  );
};

export default MultiAgentSystem;
