import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from './Account';
import { Button, Form, Container, Row, Col, Modal } from 'react-bootstrap';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [stage, setStage] = useState(1); // 1: Email input, 2: OTP input
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const { forgotPassword, confirmNewPassword } = useContext(AccountContext);
    const navigate = useNavigate()

    const handleClose = () => setShowModal(false);
    const handleShow = (message) => {
        setModalMessage(message);
        setShowModal(true);
    };

    const onEmailSubmit = (e) => {
        e.preventDefault();
        forgotPassword(email)
            .then(() => {
                handleShow('Check your email for the OTP code.');
                setStage(2); // Move to OTP input stage
            })
            .catch((err) => {
                handleShow(`Error: ${err.message}`);
            });
    };

    const onNewPasswordSubmit = (e) => {
        e.preventDefault();
        confirmNewPassword(email, otp, newPassword)
            .then(() => {
                handleShow('Password has been reset successfully.');
                setStage(1);
                setEmail('');
                setOtp('');
                setNewPassword('');
                setTimeout(() => {
                    navigate('/login');
                }, 2000);  // 2000 milliseconds delay
            })
            .catch((err) => {
                handleShow(`Error: ${err.message}`);
            });
    };

    return (
        <Container className="mt-5">
            <Row>
                <Col md={{ span: 6, offset: 3 }}>
                    <h1 className="text-center mb-4">Forgot Password</h1>
                    {stage === 1 && (
                        <Form onSubmit={onEmailSubmit}>
                            <Form.Group className="mb-3" controlId="email">
                                <Form.Label>Email:</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Enter email"
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">Send OTP</Button>
                        </Form>
                    )}
                    {stage === 2 && (
                        <Form onSubmit={onNewPasswordSubmit}>
                            <Form.Group className="mb-3" controlId="otp">
                                <Form.Label>OTP:</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                    placeholder="Enter OTP"
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="newPassword">
                                <Form.Label>New Password:</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    placeholder="New Password"
                                />
                            </Form.Group>
                            <Button variant="primary" type="submit">Reset Password</Button>
                        </Form>
                    )}
                </Col>
            </Row>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalMessage}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default ForgotPassword;
