import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebook, FaPenSquare, FaTwitter, FaInstagram, FaLinkedin, FaEnvelope } from 'react-icons/fa';
const Footer = () => (
    <footer className="footer">
            <div className="container">
                <div className="footer-flex">
                    <div className="footer-contact">
                        <h3>Lipi.ai</h3>
                        <div className='row'>
                        <Link to="/contact" className="footer-contact-link"><FaEnvelope /> Contact Us</Link>
                        </div>
                        <div className='row'>
                        <Link to="/privacy" className="footer-contact-link"><FaPenSquare /> Privacy Policy</Link>
                        </div>
                    </div>
                    <div className="footer-social">
                        <h3>Follow Us</h3>
                        <div className="social-links">
                            <a href="#" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                            <a href="#" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                            <a href="#" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
                            <a href="#" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
                        </div>
                    </div>
                </div>
            </div>
    </footer>
);

export default Footer;
