import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import Navbar from './NavBar';
import './App.css';
import Login from './Login';
import Register from './Register';
import Profile from './Profile';
import ForgotPassword from './ForgotPassword';
import Contact from './Contact';
import { Account } from './Account';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
    <HelmetProvider>
      <Account>
        <Router> {/* This line ensures that Routes are within a Router */}
          <div className="App">
            <Navbar /> {/* This includes the Navbar at the top of your app */}
            <Routes>
            <Route path="/" element={<HomePage />} /> {/* Use HomePage here */}
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
              {/* You can add more routes here */}
            </Routes>
          </div>
        </Router>
      </Account>
    </HelmetProvider>
  );
}

export default App;
