// src/BookForm.js

import React, { useState, useEffect, useRef, useContext } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { fetchSamples, fetchBookCover, fetchChapterAndBookDetails } from './api';
import { AccountContext } from './Account';

// Define your quirky strings outside of the component
const quirkyStrings = [
    "Our writers are now coming up with ideas...",
    "Grinding the ink...",
    "Sharpening the quills...",
    "Summoning the muses...",
    "Brewing a strong cup of inspiration...",
    "Dusting off the old tomes...",
    "Peering into the crystal ball...",
    "Consulting with the literary geniuses...",
    "Plotting the twists and turns...",
    "Polishing the final details..."
];

const BookForm = () => {
    const [bookType, setBookType] = useState('fiction'); // Make sure this matches the API's expected value
    const [style, setStyle] = useState('agatha');
    const [description, setDescription] = useState('');
    const [samples, setSamples] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);
    const [currentQuirkyString, setCurrentQuirkyString] = useState('');
    const [quirkyInterval, setQuirkyInterval] = useState(null);
    const [error, setError] = useState(null);
    const [bookCoverUrl, setBookCoverUrl] = useState('');
    const [chapterContent, setChapterContent] = useState('');
    const [storyIdea, setStoryIdea] = useState('');
    const [storyOptions, setStoryOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState("");
    const [chapterNumber, setChapterNumber] = useState(1);
    const [bookId, setBookId] = useState(''); 
    const [customOption, setCustomOption] = useState('');
    var [plotSoFar, setPlotSoFar] = useState('');
    const { getToken } = useContext(AccountContext); 

    const selectSampleMore = async () => {
        try {
            if (!selectedOption) {
                alert("Please select an option to continue the story.");
                return;
            }
            const token = await getToken();
            setIsGenerating(true);
            var plotlines = plotSoFar;
            plotlines = plotlines.replaceAll("**now**", "previously,");
            
            if (selectedOption.includes(":")) {
                let parts = selectedOption.split(":");
                plotlines += '\n **now** ' + parts[1].trim();
            } else {
                plotlines += '\n **now**' + selectedOption
            }

            setPlotSoFar(plotlines)
            console.log(plotlines);
            const apiStyle = style === 'agatha' ? 'agatha' : style === 'conan' ? 'conan' : 'king';
        
            const chapterDetails = await fetchChapterAndBookDetails(description, apiStyle, chapterNumber.toString(), bookId, token, plotlines); // Pass bookId to API
            setChapterNumber(chapterNumber+1);
            setChapterContent(chapterDetails.chapter_content);
            setStoryOptions(chapterDetails.options);
            setIsGenerating(false);
            setSelectedOption("");
            if (chapterRef.current) {
                setTimeout(() => {
                    chapterRef.current.scrollIntoView({ behavior: 'smooth' });
                }, 0);
            }
            setCustomOption("");
        } catch (error) {
            console.error("Error with selecting more samples:", error);
            setIsGenerating(false);
            // You may wish to handle specific types of errors differently
            // For now, we'll simply rethrow the error
            throw error;
        }
    };

    const selectSample = async (sample, chapter) => {
        try {
            const token = await getToken();
            setIsGenerating(true);
            setChapterNumber(1);
            setPlotSoFar('')
            setStoryIdea(sample.content);
            const apiStyle = style === 'agatha' ? 'agatha' : style === 'conan' ? 'conan' : 'king';
            const newBookId = uuidv4();  // Store UUID in a variable
            setBookId(newBookId);        // Also update state for future use
            const imageData = await fetchBookCover(description, apiStyle, '1', newBookId, token); // Assuming chapter_number is 1 for simplicity
            setBookCoverUrl(imageData);
            const chapterDetails = await fetchChapterAndBookDetails(description, apiStyle, chapterNumber.toString(), newBookId, token, plotSoFar); // Pass bookId to API
            setChapterNumber(chapterNumber+1);
            setChapterContent(chapterDetails.chapter_content);
            setStoryOptions(chapterDetails.options);
            setIsGenerating(false);
            if (chapterRef.current) {
                setTimeout(() => {
                    chapterRef.current.scrollIntoView({ behavior: 'smooth' });
                }, 0);
            }
        } catch (error) {
            console.error("Error fetching book cover:", error);
            setIsGenerating(false);
        }
    };

    const samplesRef = useRef(null);
    const chapterRef = useRef(null);
  
    const generateBooks = async () => {
        setIsGenerating(true);
        setError(null); // Reset error state on new request
        let quirkyIndex = 0;
        setCurrentQuirkyString(quirkyStrings[quirkyIndex]);
        setQuirkyInterval(setInterval(() => {
            quirkyIndex = (quirkyIndex + 1) % quirkyStrings.length;
            setCurrentQuirkyString(quirkyStrings[quirkyIndex]);
        }, 4000));
    
        try {
            const token = await getToken();
            const samplesFromAPI = await fetchSamples(bookType.toLowerCase(), style, description, token);
            setSamples(samplesFromAPI);
        } catch (error) {
            // Here you can handle different types of errors differently
            console.error("Error fetching samples:", error);
            if (error.response && error.response.status === 504) {
                setError("The server took too long to respond. Please try again later.");
            } else {
                setError("An unexpected error occurred. Please try again.");
            }
        } finally {
            setIsGenerating(false);
            clearInterval(quirkyInterval);
        }
    
        if (samplesRef.current) {
            setTimeout(() => {
                samplesRef.current.scrollIntoView({ behavior: 'smooth' });
            }, 0);
        }
    };
    

    // Clean up the interval when the component unmounts
    useEffect(() => {
        return () => {
        if (quirkyInterval) {
            clearInterval(quirkyInterval);
        }
        };
    }, [quirkyInterval]);

    return (
    <div className="container mt-5">
        <h1 className="mb-4">Create Your Book</h1>
        <div className="row justify-content-center">
            <div className="mb-3 col-sm-6">
                <label htmlFor="bookTypeSelect" className="form-label">I want a</label>
                <select id="bookTypeSelect" className="form-select" value={bookType} onChange={(e) => setBookType(e.target.value)}>
                    <option value="fiction">Fiction</option>
                    <option value="non-fiction">Non-Fiction</option>
                    <option value="fantasy">Fantasy</option> {/* Added option */}
                    <option value="mystery">Mystery</option> {/* Added option */}
                    <option value="thriller">Thriller</option> {/* Added option */}
                    <option value="science-fiction">Science Fiction</option> {/* Added option */}
                    <option value="romance">Romance</option> {/* Added option */}
                    <option value="horror">Horror</option> {/* Added option */}
                    <option value="historical">Historical</option> {/* Added option */}
                </select>
            </div>
            <div className="mb-3 col-sm-6">
                <label htmlFor="styleSelect" className="form-label">Book in the style of</label>
                <select id="styleSelect" className="form-select" value={style} onChange={(e) => setStyle(e.target.value)}>
                    <option value="agatha">Agatha Christie</option>
                    <option value="conan">Arthur Conan Doyle</option>
                    <option value="king">Stephen King</option>
                </select>
            </div>
            <div className="mb-3 col-sm-6">
                <label htmlFor="descriptionTextarea" className="form-label">Which talks about</label>
                <textarea 
                    id="descriptionTextarea" 
                    className="form-control" 
                    value={description} 
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="e.g., A world where magic is common and sherlock holmes is riding real dragons." // Added example
                ></textarea>
            </div>
        </div>
        <button className="btn btn-primary me-2" onClick={generateBooks} disabled={isGenerating}>Generate</button>
        <button className="btn btn-secondary">I am feeling lucky</button>
        {/* Overlay */}
        {isGenerating && (
            <div className="loading-overlay">
            <div>
                <div className="spinner-border text-light" role="status">
                <span className="visually-hidden">Loading...</span>
                </div>
                <p className="loading-text">{currentQuirkyString}</p>
            </div>
            </div>
        )}
        {error && (
            <div className="alert alert-danger" role="alert">
                {error}
            </div>
        )}
      
        <div ref={samplesRef} className="row mt-4">
        {samples.map((sample, index) => (
                <div key={index} className="col-sm-4 mb-4">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">{sample.title || `Sample ${index + 1}`}</h4>
                            <p className="card-text">{sample.content}</p>
                            <button className="btn btn-success" onClick={() => selectSample(sample, '1')}>Select This Sample</button>
                        </div>
                    </div>
                </div>
            ))}
            {chapterContent &&
            <div ref={chapterRef} className="row mt-4">
                <div className="col-md-3 book-cover">
                    {bookCoverUrl && <img src={bookCoverUrl} alt="Book Cover" className="img-fluid" />}
                </div>
                <div className="col-md-9">
                    <div className="chapter-content p-3" style={{ whiteSpace: 'pre-wrap' }}>
                        <p>{chapterContent}</p>
                    </div>

                    <div className="story-options mt-3">
                        {storyOptions.map((option, index) => (
                            <button
                                key={index}
                                className={`neon-button ${selectedOption === option ? 'active' : ''}`}
                                onClick={() => {
                                  if (!customOption) {
                                    setSelectedOption(option)
                                  }
                                }}
                                disabled={!!customOption}
                            >
                                {option}
                            </button>
                        ))}
                        <div className="custom-option-input mt-2">
                            <input
                                type="text"
                                className="form-control neon-input"
                                value={customOption}
                                onChange={(e) => {
                                  setCustomOption(e.target.value);
                                  setSelectedOption(e.target.value ? e.target.value : selectedOption);
                                }}
                                placeholder="Or write your own twist in the story..."
                                style={{
                                    border: '1px solid #00ffff',
                                    boxShadow: '0 0 2px #0033ff, 0 0 2px #0033ff'
                                }}
                            />
                        </div>
                    </div>
                    <button 
                        className="btn btn-primary mt-3" 
                        onClick={selectSampleMore} // Added onClick handler
                    >
                        Continue the Story
                    </button>
                </div>
            </div> }

        </div>
    </div>
  );
};

export default BookForm;

