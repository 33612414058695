import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import './AppNavbar.css'; // Import your custom CSS here
import { useContext, useEffect, useState } from 'react';
import { AccountContext } from './Account';

function NavBar() {
    const [status, setStatus] = useState(false);
    const { getSession, logout } = useContext(AccountContext);
  
    useEffect(() => {
      getSession()
        .then((session) => {
          //console.log('Session: ', session);
          setStatus(true);
        })
        .catch((err) => {
          //console.log('Session: ', err);
          setStatus(false);
        });
    }, [status]);

    return (
        <Navbar className="navbar-custom" expand="lg">
            <Container>
                <Navbar.Brand href="/">Lipi.AI</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="#">Blog</Nav.Link>
                        <Nav.Link href="/contact">Contact</Nav.Link>
                        {status ? (
                            <>
                            <Nav.Link href="/profile">Profile</Nav.Link>
                            <Nav.Link onClick={logout}>Logout</Nav.Link>
                            </>
                        ) : (
                            <>
                            <Nav.Link href="/login">Login</Nav.Link>
                            <Nav.Link href="/register">Register</Nav.Link>
                            </>
                        )}
                        {/* Add additional navigation links here */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavBar;
