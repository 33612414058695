import { CognitoUserPool } from 'amazon-cognito-identity-js';

/*
const poolData = {
  UserPoolId: 'us-east-1_UYEyHP5EH',
  ClientId: '7ehv243c2d25s4t5pl5nsnpitf',
};*/

const poolData = {
  UserPoolId: 'us-east-1_GF2t82HYM',
  ClientId: 'mpi3m6clufl7gk4r6ef1vspoi',
}

export default new CognitoUserPool(poolData);