import React from 'react';

const ContactDetails = () => {
  return (
    <div>
      <h2>Contact Details</h2>
      <p>
        <strong>LinkedIn:</strong>{' '}
        <a href="https://www.linkedin.com/in/aadityathakur" target="_blank" rel="noopener noreferrer">
        https://www.linkedin.com/in/aadityathakur
        </a>
      </p>
      <p>
        <strong>Email:</strong> ady92.thakur@gmail.com
      </p>
      {/* Add more contact details as needed */}
    </div>
  );
};

export default ContactDetails;
