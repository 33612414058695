import React, { useRef } from 'react';
import BookForm from './BookForm';
import MultiAgentSystem from './MultiAgentSystem';
import Footer from './Footer';

const HomePage = () => {
    const bookFormRef = useRef(null);
    const descriptionRef = useRef(null);

    const scrollToBookForm = () => {
        bookFormRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const scrollToDescriptionForm = () => {
        descriptionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <div>
      <div className="full-screen-intro d-flex align-items-center justify-content-around">
        <div className="intro-text">
          <h1>Your Story Starts Here</h1>
          <p>Discover the next chapter of your story with Lipi.AI</p>
        </div>
        <div className="intro-actions">
          <button className="btn btn-primary mb-3" onClick={scrollToBookForm}>Try the App</button>
          <button className="btn btn-secondary">Join the Waitlist</button>
          <button className="btn btn-info mb-3" onClick={scrollToDescriptionForm}>Details</button>
        </div>
      </div>
      <div ref={bookFormRef}>
        <BookForm />
      </div>
      <div ref={descriptionRef}>
        <MultiAgentSystem />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
