import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AccountContext } from './Account';
import { fetchGeneratedBooks } from './api';
import { Container, Row, Col, Button, Image, Spinner, Form, Modal } from 'react-bootstrap';

const Profile = () => {
    const [data, setData] = useState(null); 
    const [isLoading, setIsLoading] = useState(false);
    const { getSession, getToken, logout } = useContext(AccountContext);
    const [userInfo, setUserInfo] = useState({ email: '', name: '' }); // Placeholder state for user info
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const itemsPerPage = 1;
    const [currentPage, setCurrentPage] = useState(0);
    const [currentChapter, setCurrentChapter] = useState(0);
    const [bookDetails, setBookDetails] = useState([]);
    const [isBookDetailsModalOpen, setIsBookDetailsModalOpen] = useState(false);
    const navigate = useNavigate();
    // Function to handle account deletion
    const handleDeleteAccount = async () => {
        try {
            const token = await getToken();
            await axios.delete('#', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            // Log out the user and redirect them, or handle as appropriate
            logout();
            navigate('/');
        } catch (error) {
            console.error('Error deleting account', error);
        }
    };
    

    useEffect(() => {
        getSession()
            .then(() => {
                fetchData();
            })
            .catch(() => {
                navigate('/login');
            });
    }, []);

    // Handler for profile form (placeholder function)
    const handleProfileFormSubmit = (event) => {
        event.preventDefault();
        // Logic to update user profile info
    };

    const fetchData = async (lastKey, isNext = true) => {
        setIsLoading(true);
        try {
            const token = await getToken();

            const profileBookData = await fetchGeneratedBooks(token);
            setData(profileBookData.ids); 
            console.log(profileBookData.ids);

            setIsLoading(false);

        } catch (error) {
            console.error('Error fetching data', error);
            setIsLoading(false);
        }
    };

    const handleNext = () => {
        setCurrentPage(prevState => prevState + 1);
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(prevState => prevState - 1);
        }
    };

    const handleNextChapter = () => {
        setCurrentChapter(prevState => prevState + 1);
    };

    const handlePreviousChapter = () => {
        if (currentChapter > 0) {
            setCurrentChapter(prevState => prevState - 1);
        }
    };

    const handleImageClick = async bookId => {
        setIsLoading(true);
        try {
            const token = await getToken();
            const bookData = await fetchGeneratedBooks(token, bookId);
            console.log(bookData);
            setIsLoading(false);
            // Assuming bookData is an array sort it based on chapter_number
            bookData.sort((a, b) => a.chapter_number - b.chapter_number);
            setBookDetails(bookData);
            setIsBookDetailsModalOpen(true);
        }
        catch (error) {
            console.error('Error fetching data', error);
            setIsLoading(false);
        }
    };

   
    return (
        <Container className="mt-4">
            <h2 className="mb-4">Profile</h2>
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleProfileFormSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Enter email"
                                value={userInfo.email}
                                onChange={(e) => setUserInfo({ ...userInfo, email: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter name"
                                value={userInfo.name}
                                onChange={(e) => setUserInfo({ ...userInfo, name: e.target.value })}
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Update Profile
                        </Button>
                    </Form>
                    <Button variant="success" className="mt-3">Purchase Credits</Button> {/* Placeholder button */}
                    {/* Delete Account Button */}
                    <Button variant="danger" className="mt-3" onClick={() => setShowDeleteModal(true)}>
                        Delete Account
                    </Button>

                    {/* Delete Confirmation Modal */}
                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Confirm Account Deletion</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Are you sure you want to delete your account? This action is irreversible.
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                Cancel
                            </Button>
                            <Button variant="danger" onClick={handleDeleteAccount}>
                                Delete Account
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
            
            <Col md={6}>
                {isLoading ? (
                    <div className="text-center">
                        <Spinner animation="border" />
                    </div>
                ) : (
                    <div>
                        {data && (
                        <div>
                            <Row className="justify-content-md-center">
                                {data.slice(itemsPerPage * currentPage, itemsPerPage * (currentPage + 1)).map((bookId, index) => (
                                    <div key={index} className='col-xs-2 text-center'>
                                        <h5><b>My Book {currentPage+1}</b></h5>
                                        <Image 
                                            src={'https://bookcoverbucket.s3.amazonaws.com/' + bookId +'_book-art.png'} 
                                            thumbnail 
                                            fluid 
                                            className="mx-auto w-50"
                                            onClick={() => handleImageClick(bookId)}
                                        />
                                    </div>
                                ))}
                            </Row>
                        
                            <div className="mt-4">
                                <Button 
                                    variant="secondary" 
                                    onClick={handlePrevious} 
                                    className="me-2" 
                                    disabled={currentPage === 0}
                                >
                                    Previous
                                </Button>
                                <Button
                                    variant="info"
                                    onClick={() => handleImageClick(data[currentPage])}
                                    className="me-2" 
                                    disabled={data.length === 0}
                                >
                                    Read Book
                                </Button>
                                <Button 
                                    variant="primary" 
                                    onClick={handleNext} 
                                    className="me-2" 
                                    disabled={(currentPage + 1) * itemsPerPage >= data.length}
                                >
                                    Next
                                </Button>
                            </div> 
                        </div>
                        )}
                    </div>
                )}
                <Modal show={isBookDetailsModalOpen} onHide={() => setIsBookDetailsModalOpen(false)} dialogClassName="modal-90w">
                    <Modal.Header closeButton>
                        <Modal.Title>Chapter Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {bookDetails.length > 0 ? (
                            <div>
                                <h4>Chapter Number : {bookDetails[currentChapter].chapter_number}</h4>
                                <div style={{ whiteSpace: 'pre-wrap' }}> {bookDetails[currentChapter].full_chapter}</div>
                            </div>
                        ) : (
                            <div> No Chapters Found</div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button 
                            variant="primary" 
                            onClick={handlePreviousChapter} 
                            className="me-2" 
                            disabled={currentChapter === 0}
                        >
                            Previous Chapter
                        </Button>
                        <Button 
                            variant="primary" 
                            onClick={handleNextChapter} 
                            className="me-2" 
                            disabled={currentChapter >= bookDetails.length - 1}
                        >
                            Next Chapter
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Col>
            </Row>
        </Container>
    );
}

export default Profile;
