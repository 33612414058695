import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import { createContext, useState } from 'react';
import UserPool from './UserPool';

const AccountContext = createContext();

const Account = (props) => {
    const [cognitoToken, setCognitoToken] = useState(null);
    const getSession = async () => {
        await new Promise((resolve, reject) => {
            const user = UserPool.getCurrentUser();
            if (user) {
            user.getSession((err, session) => {
                if (err) {
                reject(err);
                } else {
                resolve(session);
                }
            });
            } else {
            reject();
            }
        });
    };

    const getToken = async () => {
        const user = UserPool.getCurrentUser();
        if (!user) {
            return null; // or handle this scenario appropriately
        }
    
        return new Promise((resolve, reject) => {
            user.getSession((err, session) => {
                if (err) {
                    reject(err);
                } else {
                    const token = session.getIdToken().getJwtToken();
                    resolve(token);
                }
            });
        });
    };
    


    const authenticate = async (Username, Password) => {
        await new Promise((resolve, reject) => {
        const user = new CognitoUser({
            Username,
            Pool: UserPool,
        });

        const authDetails = new AuthenticationDetails({
            Username,
            Password,
        });

        user.authenticateUser(authDetails, {
            onSuccess: (result) => {
                //console.log('login success', result);
                const idToken = result.getIdToken().getJwtToken();
                setCognitoToken(idToken); // Store the token
                resolve(result);
                resolve(result);
            },
            onFailure: (err) => {
            //console.log('login failure', err);
            reject(err);
            },
            newPasswordRequired: (data) => {
            //console.log('new password required', data);
            resolve(data);
            },
        });
        });
    };

  const logout = () => {
    const user = UserPool.getCurrentUser();
    user.signOut();
    window.location.href = '/';
  };

  const forgotPassword = (Username) => {
    const user = new CognitoUser({
        Username,
        Pool: UserPool
    });

    return new Promise((resolve, reject) => {
        user.forgotPassword({
            onSuccess: () => {
                resolve();
            },
            onFailure: (err) => {
                reject(err);
            }
        });
    });
};

    const confirmNewPassword = (Username, code, newPassword) => {
        const user = new CognitoUser({
            Username,
            Pool: UserPool
        });

        return new Promise((resolve, reject) => {
            user.confirmPassword(code, newPassword, {
                onSuccess: () => {
                    resolve();
                },
                onFailure: (err) => {
                    reject(err);
                }
            });
        });
    };

  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout, getToken, cognitoToken, forgotPassword, confirmNewPassword  }}>
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };